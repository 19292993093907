<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Convention collective" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="6" lg="8" md="8" sm="10" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewBoxComponent title="Détail d'une convention collective">
          <template v-slot:items>
            <InputFieldComponent :cols="cols" label="nom" :value="name" />

            <InputFieldComponent
              :cols="cols"
              label="nom complet"
              :value="label"
            />

            <InputFieldComponent :cols="cols" label="lien" :value="link" />

            <InputFieldComponent :cols="cols" label="idcc" :value="idcc" />

            <InputFieldComponent :cols="cols" label="numéro" :value="number" />

            <InputFieldComponent label="échelon" :cols="cols">
              <template v-slot:value>
                <v-checkbox
                  :value="caGrade"
                  readonly
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  color="primary"
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>

            <InputFieldComponent label="catégorie" :cols="cols">
              <template v-slot:value>
                <v-checkbox
                  :value="caCategory"
                  readonly
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  color="primary"
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>

            <InputFieldComponent label="niveau" :cols="cols">
              <template v-slot:value>
                <v-checkbox
                  :value="caLevel"
                  readonly
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  color="primary"
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>

            <InputFieldComponent label="coefficient" :cols="cols">
              <template v-slot:value>
                <v-checkbox
                  :value="caCoefficient"
                  readonly
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  color="primary"
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>

            <InputFieldComponent label="points" :cols="cols" :divider="false">
              <template v-slot:value>
                <v-checkbox
                  :value="caPoints"
                  readonly
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-outline"
                  color="primary"
                  hide-details
                  class="my-0"
                ></v-checkbox>
              </template>
            </InputFieldComponent>
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { CollectiveAgreementService } from "@/service/user/collective_agreement_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

export default {
  name: "DetailsCollectiveAgreement",
  components: {
    Workflow,
    TitleAndReturnComponent,
    DetailViewBoxComponent,
    InputFieldComponent,
  },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service */
      collectiveAgreementsService: null,

      /**l'identifiant de la convention collective à visualiser. */
      collectiveAgreementId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      name: null,
      label: null,
      link: null,
      idcc: null,
      number: null,
      caGrade: false,
      caCategory: false,
      caLevel: false,
      caCoefficient: false,
      caPoints: false,

      cols: [5, 7],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        //Récupération de l'item par son id
        let item = await this.collectiveAgreementsService.getById(
          this.collectiveAgreementId
        );

        this.init(item);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    init(entity) {
      this.name = entity.name || "-";
      this.label = entity.label || "-";
      this.link = entity.link || "-";
      this.idcc = entity.idcc || "-";
      this.number = entity.number || "-";
      this.caGrade = entity.caGrade;
      this.caCategory = entity.caCategory;
      this.caLevel = entity.caLevel;
      this.caCoefficient = entity.caCoefficient;
      this.caPoints = entity.caPoints;
    },
    getClassForCheckbox(value) {
      return value ? "success" : null;
    },
  },
  computed: {},
  mounted() {
    // Instanciation de la classe service
    this.collectiveAgreementsService = new CollectiveAgreementService(
      this.$api.getCollectiveAgreementApi()
    );

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.collectiveAgreementId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>