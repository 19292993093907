var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "6", lg: "8", md: "8", sm: "10", xs: "12" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("TitleAndReturnComponent", {
                    attrs: { title: "Convention collective" },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: { xl: "6", lg: "8", md: "8", sm: "10", xs: "12" },
                    },
                    [
                      _c("v-progress-linear", {
                        attrs: { indeterminate: "", active: _vm.loading },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("DetailViewBoxComponent", {
                attrs: { title: "Détail d'une convention collective" },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function () {
                      return [
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "nom",
                            value: _vm.name,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "nom complet",
                            value: _vm.label,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "lien",
                            value: _vm.link,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "idcc",
                            value: _vm.idcc,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            cols: _vm.cols,
                            label: "numéro",
                            value: _vm.number,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: { label: "échelon", cols: _vm.cols },
                          scopedSlots: _vm._u([
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _c("v-checkbox", {
                                    staticClass: "my-0",
                                    attrs: {
                                      value: _vm.caGrade,
                                      readonly: "",
                                      "off-icon": "mdi-checkbox-blank-outline",
                                      "on-icon": "mdi-checkbox-outline",
                                      color: "primary",
                                      "hide-details": "",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("InputFieldComponent", {
                          attrs: { label: "catégorie", cols: _vm.cols },
                          scopedSlots: _vm._u([
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _c("v-checkbox", {
                                    staticClass: "my-0",
                                    attrs: {
                                      value: _vm.caCategory,
                                      readonly: "",
                                      "off-icon": "mdi-checkbox-blank-outline",
                                      "on-icon": "mdi-checkbox-outline",
                                      color: "primary",
                                      "hide-details": "",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("InputFieldComponent", {
                          attrs: { label: "niveau", cols: _vm.cols },
                          scopedSlots: _vm._u([
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _c("v-checkbox", {
                                    staticClass: "my-0",
                                    attrs: {
                                      value: _vm.caLevel,
                                      readonly: "",
                                      "off-icon": "mdi-checkbox-blank-outline",
                                      "on-icon": "mdi-checkbox-outline",
                                      color: "primary",
                                      "hide-details": "",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("InputFieldComponent", {
                          attrs: { label: "coefficient", cols: _vm.cols },
                          scopedSlots: _vm._u([
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _c("v-checkbox", {
                                    staticClass: "my-0",
                                    attrs: {
                                      value: _vm.caCoefficient,
                                      readonly: "",
                                      "off-icon": "mdi-checkbox-blank-outline",
                                      "on-icon": "mdi-checkbox-outline",
                                      color: "primary",
                                      "hide-details": "",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "points",
                            cols: _vm.cols,
                            divider: false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _c("v-checkbox", {
                                    staticClass: "my-0",
                                    attrs: {
                                      value: _vm.caPoints,
                                      readonly: "",
                                      "off-icon": "mdi-checkbox-blank-outline",
                                      "on-icon": "mdi-checkbox-outline",
                                      color: "primary",
                                      "hide-details": "",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }